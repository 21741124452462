import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { Params } from '@angular/router';
import { MentionMeService } from '@x/common/referral/mention-me/services/mention-me.service';
import { ScriptLoaderScript } from '@x/common/script-loader/script-loader.service';

@Component({
  selector: 'x-mm-referrer-integration-tag',
  templateUrl: './mm-referrer-integration-tag.component.html',
  styleUrls: ['./mm-referrer-integration-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmReferrerIntegrationTagComponent implements OnChanges, OnDestroy {
  @Input() situation: string;

  params: Params;
  loadScript: ScriptLoaderScript | undefined;

  constructor(public mentionMeService: MentionMeService) {}

  ngOnDestroy(): void {
    if (!this.loadScript) return;

    const key = `embed${this.situation}`;
    this.mentionMeService.unloadScript(this.loadScript.src, key);
  }

  async ngOnChanges() {
    if (!this.mentionMeService.isConfigured) return;

    if (this.situation) {
      this.params = { situation: this.situation, implementation: 'embed' };

      this.loadScript = await this.mentionMeService.loadScript('referreroffer', this.params);
    }
  }
}
