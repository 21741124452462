import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { Params } from '@angular/router';
import { MmAmountsPipe } from '@x/common/referral/mention-me/pipes/mm-amounts.pipe';
import { MentionMeService } from '@x/common/referral/mention-me/services/mention-me.service';
import { ScriptLoaderScript } from '@x/common/script-loader/script-loader.service';
import { IShopAuthUser, IShopOrder } from '@x/ecommerce/shop-client';

@Component({
  selector: 'x-mm-referrer-tag',
  templateUrl: './mm-referrer-tag.component.html',
  styleUrls: ['./mm-referrer-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmReferrerTagComponent implements OnChanges, OnDestroy {
  @Input() checkoutOrder: IShopOrder;
  @Input() user: IShopAuthUser | null | undefined;
  @Input() situation: string;

  loadScript: ScriptLoaderScript | undefined;
  params: Params;

  constructor(private mentionMeService: MentionMeService, private mmAmountsPipe: MmAmountsPipe) {}

  ngOnDestroy(): void {
    if (!this.loadScript) return;

    const key = `popup${this.situation}`;
    this.mentionMeService.unloadScript(this.loadScript.src, key);
  }

  async ngOnChanges() {
    if (!this.mentionMeService.isConfigured) return;

    if (this.checkoutOrder && this.user && this.situation) {
      // set params
      this.params = {
        firstname: this.user.firstName,
        surname: this.user.lastName,
        email: this.user.email,
        order_number: this.checkoutOrder.number,
        order_subtotal: this.mmAmountsPipe.transform(this.checkoutOrder.total),
        order_currency: this.checkoutOrder.currency,
        situation: this.situation,
        order_discount_amount: this.mmAmountsPipe.transform(
          this.checkoutOrder.adjustmentsTotal,
          true,
        ),
        order_item_count: this.checkoutOrder.items.reduce<number>((acc, item) => {
          return acc + item.quantity;
        }, 0),
      };

      this.loadScript = await this.mentionMeService.loadScript('referreroffer', this.params);
    }
  }
}
