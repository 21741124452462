import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ScriptLoaderService } from './script-loader.service';

@NgModule({
  imports: [CommonModule],
  exports: [],
  declarations: [],
  providers: [ScriptLoaderService],
})
export class ScriptLoaderModule {}
