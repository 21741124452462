import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'mmAmounts' })
export class MmAmountsPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  transform(cents: number, removeNegative: boolean = false): string | null {
    const centsConverted = removeNegative ? Math.abs(cents / 100) : cents / 100;
    return this.decimalPipe.transform(centsConverted, '1.2');
  }
}
