import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { MoneyModule } from '@x/common/money';
import { MmDashboardComponent } from '@x/common/referral/mention-me/components/mm-dashboard/mm-dashboard.component';
import { MmRefereeTagComponent } from '@x/common/referral/mention-me/components/mm-referee-tag/mm-referee-tag.component';
import { MmReferrerIntegrationTagComponent } from '@x/common/referral/mention-me/components/mm-referrer-integration-tag/mm-referrer-integration-tag.component';
import { MmReferrerTagComponent } from '@x/common/referral/mention-me/components/mm-referrer-tag/mm-referrer-tag.component';
import { MmAmountsPipe } from '@x/common/referral/mention-me/pipes/mm-amounts.pipe';
import { MentionMeService } from '@x/common/referral/mention-me/services/mention-me.service';
import { ScriptLoaderModule } from '@x/common/script-loader/script-loader.module';

@NgModule({
  providers: [MentionMeService, DecimalPipe, MmAmountsPipe],
  declarations: [
    MmReferrerTagComponent,
    MmRefereeTagComponent,
    MmReferrerIntegrationTagComponent,
    MmAmountsPipe,
    MmDashboardComponent,
  ],
  imports: [CommonModule, MoneyModule, ScriptLoaderModule],
  exports: [MmReferrerTagComponent, MmRefereeTagComponent, MmReferrerIntegrationTagComponent],
})
export class MentionMeModule {}
